<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">关键字</div>
					<div class="inputs_box">
						<!-- <input type="text" placeholder="姓名/证件号码" v-model="forms.keyword"> -->
						<el-input clearable type="text" placeholder="姓名/证件号码" v-model="forms.keyword" />
					</div>
				</div>

				<div class="search_flex">
					<div class="texts">调动前部门岗位</div>
					<div class="inputs_box">
						<el-cascader placeholder="请选择" :options="gw_list" collapse-tags :props="props"
						:show-all-levels="false" v-model="gw_ac1" clearable></el-cascader>
					</div>
				</div>

				<div class="search_flex">
					<div class="texts">调动后部门岗位</div>
					<div class="inputs_box">
						<el-cascader placeholder="请选择" :options="gw_list" collapse-tags :props="props"
						:show-all-levels="false" v-model="gw_ac2" clearable></el-cascader>
					</div>
				</div>

				<div class="search_flex">
					<div class="texts">调令下发时间</div>
					<div class="inputs_box">
						<el-date-picker   :editable="false" 
							value-format="yyyy-MM-dd" :default-time="['00:00:00', '23:59:59']" v-model="times" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
						<!-- <el-date-picker v-model="times" value-format="yyyy-MM-dd" type="datetime" placeholder="请选择">
						</el-date-picker> -->
					</div>
				</div>

				<div class="search_flex">
					<div class="btn_ends" @click="handle_search">搜索</div>
				</div>
			</div>
		</div>

		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">
					<div class="btns" @click="add_dling()">
						新增调令
					</div>
				</div>
			</div>


			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- 暂时无需 -->
					<!-- <el-table-column type="selection" width="55"></el-table-column> -->

					<el-table-column fixed prop="name" label="姓名" width="130">
						<template slot-scope="scope">
							<el-popover trigger="hover" placement="top" :content="scope.row.name">
								<div slot="reference" class="name-wrapper" style="color: #2373C8;">
									{{ scope.row.name }}
								</div>
							</el-popover>
						</template>
					</el-table-column>

					<el-table-column prop="order_no" label="调令号" width="200"></el-table-column>
					<el-table-column prop="q_bm_title" label="调动前部门" width="150"></el-table-column>
					<el-table-column prop="q_gw_title" label="调动前岗位" width="200"></el-table-column>
					<el-table-column prop="h_bm_title" label="调动后部门" width="180"></el-table-column>
					<el-table-column prop="h_gw_title" label="调动后岗位" width="180"></el-table-column>
					<el-table-column prop="xf_time" label="调令下发日期" width="180"></el-table-column>
					<el-table-column prop="bd_time" label="报道日期"></el-table-column>
					<!-- 操作模块   -->
					<el-table-column label="操作" fixed="right"  width="100">
						<template slot-scope="scope">
							<el-dropdown @command="handleCommand">
								<span class="el-dropdown-link">
									<div class="btn_img">
										<img src="../../assets/img/employee/caozuo.png" alt="">
									</div>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="{ key: 1, row: scope.row }">编辑调令</el-dropdown-item>
									<el-dropdown-item :command="{ key: 2, row: scope.row }">查看全部</el-dropdown-item>
									<el-dropdown-item :command="{ key: 3, row: scope.row }">查看调令文件</el-dropdown-item>


								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
		<add_dl ref="add_dl" @handle_get_list="handle_get_list"></add_dl>

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的

	import add_dl from "@/components/diaoling/add_diaoling.vue"; //新增调动


	export default {
		components: {
			page,
			add_dl
		},
		data() {
			return {
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				times:[],//时间
				forms: {
					keyword: '',
					q_bm_title:'',//变动前部门
					q_gw_title: '',//变动前岗位
					h_bm_title:'',//变动后部门
					h_gw_title: '',//变动后岗位
				},
				list: [],
				props: {
					multiple: false,
					value:'title',
					label:'title'
				},
				gw_ac1:[],//前岗位
				gw_ac2:[],//后岗位
			  
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: '', //时间范围
				item_row: {},
				gw_list:[],//获取的岗位
			};
		},

		methods: {
			add_dling() {
				this.$refs.add_dl.init()
			},
			handleCommand(e) {
				console.log(e)
				this.item_row = e.row
				let i = e.key
				switch (i) {
					//编辑调令
					case 1:
						this.handle_bianji()
						break;
					//查看全部调令
					case 2:
						this.handle_chakan_all()
						break;
							// 查看调令文件
					case 3:
						this.handle_chakan()
						break;

				}
			},
			handle_bianji() {
				console.log("编辑调令")
				this.$refs.add_dl.init(this.item_row)

			},
			handle_chakan() {
				console.log("查看调令文件")
                // window.open(row.pdf.path)
				window.open(this.item_row.pdf.path)
			},
			handle_chakan_all(){
				this.$router.push({
					path:'/Employee_transfers_user',
					query:{
						id:this.item_row.user_id
					}
				})
			},

			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				if(this.gw_ac1.length!=0){
					console.log('gw_ac1',this.gw_ac1)
					let last_num=this.gw_ac1.length-1
					let q_bm_title=''
					let q_gw_title=this.gw_ac1[last_num]//岗位
					this.gw_ac1.forEach((e,i)=>{
						if(i<last_num){
							let text=''
							if(i!=0){
								text='/'
							}
							q_bm_title=q_bm_title+text+e
						}
					})
					// this.forms.q_bm_title
					console.log('获取的部门',q_bm_title)
					console.log('获取的岗位',q_gw_title)
					this.forms.q_bm_title=q_bm_title
					this.forms.q_gw_title=q_gw_title
				}else{
					this.forms.q_bm_title=''
					this.forms.q_gw_title=''
				}
				if(this.gw_ac2.length!=0){
					console.log('gw_ac2',this.gw_ac2)
					let last_num=this.gw_ac2.length-1
					let h_bm_title=''
					let h_gw_title=this.gw_ac2[last_num]//岗位
					this.gw_ac2.forEach((e,i)=>{
						if(i<last_num){
							let text=''
							if(i!=0){
								text='/'
							}
							h_bm_title=h_bm_title+text+e
						}
					})
					// this.forms.q_bm_title
					console.log('获取的部门',h_bm_title)
					console.log('获取的岗位',h_gw_title)
					this.forms.h_bm_title=h_bm_title
					this.forms.h_gw_title=h_gw_title
				}else{
					this.forms.h_bm_title=''
					this.forms.h_gw_title=''
				}
				if(this.times){
					this.forms.xf_time_s=this.times[0]
					this.forms.xf_time_e=this.times[1]
				}else{
					this.forms.xf_time_s=''
					this.forms.xf_time_e=''
				}
				this.loading = true
				this.$api("getUserRedeployList", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
						this.list.forEach((e)=>{
							if(e.pdf){
								e.pdf=JSON.parse(e.pdf)
							}
						})
					} else {
						alertErr(res.msg)
					}
				});
			},

			//获取岗位
			get_gw_list(){
				this.loading = true
				this.$api("getCategoryAllList", {
					
				}, "get").then((res) => {
					console.log('所有的岗位',res)
					this.loading = false
					if (res.code == 200) {
						let level=0
						let list=[]
						let data=res.data
						data.forEach((e,i)=>{
							if(e.level>level){
								level=e.level
							}
							this.$set(e,'children',[])
						})
						console.log('最高登记',level)

						for (let i = 1; i <= level; i++) {
							let level_list=[]
							data.forEach((e)=>{
								if(e.level==i){
									level_list.push(e)
								}
							})
							list.push(level_list)
						}
						console.log('列表',list)
						list[list.length-1].forEach((e)=>{
							this.$delete(e,'children')
						})
						// 总列表  最后一位的下标
						this.gw_list_data(list,list.length-1)

						
						
					} else {
						alertErr(res.msg)
					}
				});
			},
			//处理岗位列表
			gw_list_data(lists,indexs){
				let index1=indexs //当前下标
				let index2=indexs-1 //上级下标
				let list=lists
				list[index1].forEach((e)=>{
					list[index2].forEach((v)=>{
						if(v.id==e.parent_id){
							v.children.push(e)
						}
					})
				})
				list[index2].forEach((e)=>{
					if(e.children.length==0){
						this.$delete(e,'children')
					}
				})
				if(index2==0){
					console.log('最后的数据',list)
					this.gw_list=list[0]
					return
				}
				this.gw_list_data(list,index2)
			},


		},
		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
			this.get_gw_list()
		},
		mounted() {

		}


	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 37px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 35px;
					margin-bottom: 20px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>